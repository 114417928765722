
/* MEMO: inspired by https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
/* Chrome, Safari, Edge, Opera */
input.impermaxInput::-webkit-outer-spin-button,
input.impermaxInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.impermaxInput[type=number] {
  -moz-appearance: textfield;
}
