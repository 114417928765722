.interaction-button {
  display: block;
  height: 54px;
  border-radius: 0.75rem;
  font-size: 1.125rem;
  background-color: #05c881;
  color: #fff;
  width: 100%;
  border: 0;
  outline: 0 !important;

  &.disabled { 
    background-color: #dcdee3;
    color: #76797f;
  }

  &.disabled, &.done, &.pending {
    cursor: auto;
  }

  &.ready:hover {
    background-color: #07d186;
  }

  .spinner-border {
    position: relative;
    margin-left: 7px;
    bottom: 2px;
  }

  .fa-check {
    background: #202020;
    position: relative;
    top: 1px;
    margin-left: 7px;
    border-radius: 50%;
    padding: 4px;
    width: 20px;
    height: 20px;
  }
}